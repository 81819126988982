import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Product, SearchSuggestion } from '../Models/search.model';

@Injectable({
  providedIn: 'root'
})
export class SearchHelperService {
  constructor(private http: HttpClient) {}

  searchSuggestions(searchTerm: string): Observable<SearchSuggestion[]> {
    return this.http.get<SearchSuggestion[]>(
      environment.apiBaseUrl + `api/Search/suggestions?searchTerm=${searchTerm}`
    );
  }

  searchSuggestionsByTokens(searchTerm: string): Observable<SearchSuggestion[]> {
    return this.http.get<SearchSuggestion[]>(
      environment.apiBaseUrl + `api/Search/suggestions/using-tokens?searchTerm=${searchTerm}`
    );
  }

  searchProducts(searchTerm: string): Observable<Product[]> {
    return this.http.get<Product[]>(
      environment.apiBaseUrl + `api/Search/products?searchTerm=${searchTerm}`
    );
  }
}
