import { Component, OnInit } from '@angular/core';
import {Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import { GiftyAppService } from './Services/gifty-app.service';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { RouterEventService } from './shared/RouterEvent.service';


@Component({
  selector: 'xeyn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'WrapMe';
  currentRoute: string;
  loading = false;
  init = false;
  isAdmin: boolean = false;
  isLogIn: boolean = false;

  constructor(private service: GiftyAppService, private router: Router, private meta: Meta, private siteTitle: Title, private rEventService: RouterEventService) {
    this.handlePopStateOfQuickDetailWindows();
    this.currentRoute = "";
    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.loading = true;
          // Show progress spinner or progress bar
          break;
        }

        case event instanceof NavigationEnd: {
          // Hide progress spinner or progress bar
          this.loading = false;
          this.init = true;
          this.currentRoute = (event as NavigationEnd).url;
          this.isAdmin = this.currentRoute.includes('backoffice');
          this.isLogIn = this.currentRoute.includes('login');
          break;
        }
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          // Hide progress spinner or progress bar
          this.loading = false;
          this.init = true;
          break;
        }
        default: {
          break;
        }
      }
    });
    this.meta.addTags([
      {name: 'description', content: 'Welcome to WrapMe, where the joy of giving meets the convenience of online shopping! At WrapMe, we understand the importance of special moments and the thoughtfulness that goes into selecting the perfect gift.'},
      {name: 'author', content: 'WrapMe'},
      {name: 'keywords', content: 'best customize gift delivery platform, customize gift delivery platform, custom gifts, customzed gifts in srilanka, , customzed gifts in colombo, gift delivery in srilanka, happiness wrapped in a box, hampers in sri lanka, gift packages in colombo, gift packages'},
    ]);
    this.setTitle('WrapMe | Happiness wrapped in a box');
  }
  public setTitle( newTitle: string) {
    this.siteTitle.setTitle( newTitle );
  }

  handlePopStateOfQuickDetailWindows() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Your condition to decide which route to navigate to
        if (event.navigationTrigger === 'popstate') {
          if(this.rEventService.routes[this.rEventService.routes.length - 2].includes('/store/')) {
            window.location.replace(environment.appBaseUrl);
          }
        }
      }
    });
  }
}
