const appBaseUrl = `${window.location.protocol}//${window.location.hostname}${(location.port ? ':' + location.port : '')}`;
const apiBaseUrl = 'https://wrapme-demo-api.azurewebsites.net/';
const cdnUrl = 'https://wrapme-aeekdtgje4fwhkfs.z02.azurefd.net/';
const storageFolder = 'dev';
const imageTransformations = 'hres,lres,thumb,medium,low,mobile';
const requestFormat = '.webp';
const defaultDeliveryDateBuffer = 5;
const supportPhoneNumber = '+94 XXX XXX XXX';
const supportEmailAddress = 'Admin@WrapMe.lk';
const wrapMeAddress = 'No 185/5 1B, Havelock Road, Colombo 05, 00500';
const xeynergyAddress = 'No 546/7/4, Galle Road, Colombo 03, 00300';
const wrapMeBankAccName = 'WRAPME (PRIVATE) LIMITED';
const accBranch = 'Malabe';
const accNumber	= '003910011226';
const bankName = 'Sampath Bank PLC';
const useWrapMeAddress = true;
const enableLogs = true;
const underMaintenance = false;
const maximumHeroSliderImages = 5;
const defaultCustomItemMaxCount = 25;
const captchaKey = '6Lfb7qUpAAAAAKluh4VkNR9L2KCwhw00wqKKP1Wq';
const inventoryProvider = 'wrapme';

export const environment = {
  production: true,
  apiBaseUrl: apiBaseUrl,
  appBaseUrl: appBaseUrl,
  cdnUrl: cdnUrl,
  storageFolder: storageFolder,
  imageTransformations: imageTransformations,
  requestFormat: requestFormat,
  imageUploadAdminApiBaseUrl: 'https://wrapme-cloudfile-upload-api.azurewebsites.net/',
  tenantId : '7696e81c-56f7-11ee-8c99-0242ac120002', // `WrapMe` tenant id
  deliveryDateBuffer: defaultDeliveryDateBuffer,
  supportPhone: supportPhoneNumber,
  supportEmail: supportEmailAddress,
  wrapMeAddress: wrapMeAddress,
  xeynergyAddress: xeynergyAddress,
  wrapMeBankAccName: wrapMeBankAccName,
  accBranch: accBranch,
  accNumber: accNumber,
  bankName: bankName,
  useWrapMeAddress: useWrapMeAddress,
  enableLogs: enableLogs,
  underMaintenance: underMaintenance,
  maximumHeroSliderImages: maximumHeroSliderImages,
  defaultCustomItemMaxCount: defaultCustomItemMaxCount,
  captchaKey: captchaKey,
  inventoryProvider: inventoryProvider
};
