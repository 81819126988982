import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ProductItemComponent} from "../shared/product-item/product-item.component";
import {ProductPackageComponent} from "../shared/product-package/product-package.component";
import {EmptyStateComponent} from "../shared/empty-state/empty-state.component";
import {CommonsModule} from "../commons/commons.module";
import {MatSnackBarModule} from "@angular/material/snack-bar";


@NgModule({
  declarations: [
    ProductItemComponent,
    ProductPackageComponent,
    EmptyStateComponent
  ],
  imports: [
    CommonModule,
    CommonsModule,
    MatSnackBarModule
  ],
  exports: [
    ProductItemComponent,
    ProductPackageComponent,
    EmptyStateComponent
  ]
})
export class ProductModule {
}
