import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component,  HostListener,  OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Subject,
  takeUntil,
  forkJoin,
  take,
  mergeMap,
  map,
  of,
} from 'rxjs';
import { GiftItem } from 'src/app/Models/gift-item.model';
import { ProductDetailQuickViewService } from 'src/app/Services/product-detail-quick-view-service/product-detail-quick-view.service';
import { ShoppingCartService } from '../../Services/shopping-cart.service';
import {
  CartItem,
  ShoppingCart,
  UnitsOfMeasure,
} from '../../Models/shopping-cart';
import { GiftPackage } from 'src/app/Models/gift-package.model';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from "@angular/material/snack-bar";
import {HttpErrorResponse} from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { CloudFile } from 'src/app/Models/cloud-file';
import { AddToCartAmountUpdateMessageComponent } from '../add-to-cart-amount-update-message/add-to-cart-amount-update-message.component';
import { CarouselComponent, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { RouterEventService } from '../RouterEvent.service';
import {SpinnerService} from "@xyg/spinner";
import { Discount } from 'src/app/Models/discount.model';

@Component({
  selector: 'xgy-product-detail-quick-view',
  templateUrl: './product-detail-quick-view.component.html',
  styleUrls: ['./product-detail-quick-view.component.scss'],
  animations: [
    // Trigger is used here
    trigger('animate', [
      state(
        'green',
        style({
          'background-color': '#00D250',
          transform: 'translateX(0)',
        })
      ),
      state(
        'red',
        style({
          'background-color': '#f46f61',
          color: '#ffffff',
          transform: 'translateX(0)',
        })
      ),
      state(
        'white',
        style({
          'background-color': '#ffffff',
          transform: 'translateX(0)',
        })
      ),
      transition('green => red', animate(1200)),
      transition('red => green', animate(1000)),
    ]),
  ],
})
export class ProductDetailQuickViewComponent implements OnInit, OnDestroy {
  inputAnimate = '';
  isMessageClosed = false;
  countExceededMsg = false;
  lessThanMinimumMsg = false;
  images: any[] = [];
  itemCount!: number;
  productItem!: GiftItem;
  productPackage!: GiftPackage;
  isItem!: boolean;
  imageIds!: string;
  currentCart!: ShoppingCart;
  cartId!: number;
  addingCartSuccess = false;
  isOutStock!: boolean;
  reducedPrice!: number;
  timer!: NodeJS.Timeout;
  imageWidth: number = 0;
  isCustomizedGift!: boolean;
  maxItemCount: number = environment.defaultCustomItemMaxCount;

  //additional details of each product
  additionalDetails!: string[];

  private unsubscribe = new Subject<void>();
  imagesSlider: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['◄', '►'],
    items: 1,
    nav: false,
    autoplay: true,
    autoWidth: true,
  };

  thumbnailsSlider: OwlOptions = {
    items:5,
    dots:false,
    margin:5,
    autoWidth: true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    center: false,
    navText: ['◄', '►'],
    nav: false,
    autoplay: false
  };

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  selectedImage!: number;
  activeSlides!: SlidesOutputData;
  @ViewChild('thumbSlider') thumbSlider!: CarouselComponent;
  previousUrl!: string;

  constructor(
    public activeModal: NgbActiveModal,
    private productDetailViewService: ProductDetailQuickViewService,
    private shoppingCartService: ShoppingCartService,
    private _snackBar: MatSnackBar,
    private spinner: SpinnerService,
    private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute,
    private location: Location,
    private routerEventService: RouterEventService
  ) {}

  ngOnInit(): void {
    this.spinner.requestStarted();
    this.isItem = this.productDetailViewService.isItem;
    this.isOutStock = this.productDetailViewService.isOutStock;
    this.reducedPrice = this.productDetailViewService.reducedPrice;

    if (this.isItem) {
      forkJoin({
        item: this.productDetailViewService.productItemSubject.pipe(take(1)),
        cart: this.shoppingCartService.loadShoppingCart(),
      })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res.item && res.cart) {
            this.productItem = res.item;
            this.addParam(res.item.itemId);
            const cartItem = res.cart?.cartItems?.filter(
              (cItem) =>
                Number(cItem.product.inventoryId.split('-')[1]) ==
                this.productItem.itemId
            )[0];
            this.itemCount = cartItem ? cartItem.quantity : 1;
            if (res.item.itemLongDesc) {
              this.additionalDetails = res.item.itemLongDesc.replace(/^<p>|<\/p>$/g, '').split(',');
            }
            this.getItemImages(res.item);
            this.spinner.requestEnded();
            if((res.item.giftItemDiscounts as any[]).length > 0){
              this.setDiscount(true);
            }
          }
        });
      this.productDetailViewService.changeItem();
    } else {
      forkJoin({
        item: this.productDetailViewService?.productPackageSubject?.pipe(
          take(1)
        ),
        cart: this.shoppingCartService.loadShoppingCart(),
      })
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((res) => {
          if (res.item && res.cart) {
            this.productPackage = res.item;
            this.addParam(res.item.packageId);
            const cartItem = res.cart?.cartItems?.filter(
              (cItem) =>
                Number(cItem.product.inventoryId.split('-')[1]) ==
                this.productPackage.packageId
            )[0];
            this.itemCount = cartItem ? cartItem.quantity : 1;
            if (res.item.longDescription) {
              this.additionalDetails = res.item.longDescription.replace(/^<p>|<\/p>$/g, '').split(',');
            }
            this.getPackageImages(res.item);
            this.spinner.requestEnded();
            if((res.item.giftPackageDiscounts as any[]).length > 0){
              this.setDiscount(false);
            }
          }
        });
      this.productDetailViewService.changePackage();
    }
  }

  setDiscount(isItem: boolean) {
    const discount = isItem
      ? (this.productItem.giftItemDiscounts as any[])[0].discount as Discount
      : (this.productPackage.giftPackageDiscounts as any[])[0].discount as Discount;
    const price = isItem
      ? this.productItem.price
      : this.productPackage.price;
    const discountType = discount?.discountType as unknown as string | undefined;

    if (!discountType) {
      return;
    }

    if(this.reducedPrice === price?.amount) {
      switch (discountType) {
        case 'FixedAmount':
        case 'CategoryAmount':
            this.reducedPrice = (price?.amount ?? 0) - (discount?.discountValue?.amount ?? 0);
            break;
        case 'Percentage':
        case 'CategoryPercentage':
            this.reducedPrice = (price?.amount ?? 0) - ((price?.amount ?? 0) * (discount?.discountPercentage ?? 0) / 100);
            break;
        default:
            throw new Error('Invalid discount type');
      }
    }
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.activeModal.dismiss();
    if(this.routerEventService.routes.length >= 2 && this.routerEventService.routes[this.routerEventService.routes.length - 2].includes('/store/')) {
      this.router.navigateByUrl('');
      //window.location.replace(environment.appBaseUrl);
    }
  }

  addParam(productId: number) {
    this.previousUrl = this.location.path()
    console.log(this.previousUrl)
    const separator = this.getSeparator(this.previousUrl);
    const urlParts = this.previousUrl.split('&');
    let baseUrl = urlParts[0]; // Get the base URL
    let newUrl = `${baseUrl}${separator}&product=${productId}`; // Construct the new URL

    if(this.previousUrl == '') {
      if(this.productPackage) {
        baseUrl = 'store?page=buy-from-store'
        newUrl = `store/package/${productId}`;
        this.location.replaceState(newUrl);
      } else if(this.productItem) {
        baseUrl = 'store?page=customized-gifts'
        newUrl = `store/item/${productId}`;
        this.location.replaceState(newUrl);
      }
    } else {
      this.location.replaceState(newUrl);
    }

  }

  getSeparator(currentUrl: string) {
    if(currentUrl.includes('?store') && !currentUrl.includes('&')) {
      return '&';
    } else return '';
  }

  OnDestroy() {
    this.activeModal.dismiss();
  }

  getItemImages(item: GiftItem) {
    item.cloudFiles.forEach((item: any) => this.images.push(item.cloudFile));
    this.setCarouselWidth();
  }

  getPackageImages(pack: GiftPackage) {
    pack.cloudFiles.forEach((item: any) => this.images.push(item.cloudFile));
    this.setCarouselWidth();
  }

  setCarouselWidth(){
    if(this.images.length <= 2){
      this.imageWidth = 100;
    }
  }

  getAltMsg(img: CloudFile){
    if(img){
      return img.name;
    };

    return 'Product image';
  }

  closeClicked() {
    this.activeModal.dismiss();
    this.location.replaceState(this.previousUrl);
  }

  ngOnDestroy() {
    this.location.replaceState(this.previousUrl);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  decrementCont() {
    clearTimeout(this.timer);
    if (this.itemCount > 1) {
      this.itemCount--;
      this.countExceededMsg = false;
    } else {
      this.lessThanMinimumMsg = true;
    }
    this.inputAnimate = 'red';
    setTimeout(() => {
      this.inputAnimate = 'white';
    }, 1200);
    this.timer = setTimeout(() => {
      this.lessThanMinimumMsg = false;
    }, 10000);
  }

  incrementCont() {
    clearTimeout(this.timer);
    if (this.itemCount >= 1 && this.itemCount < this.maxItemCount) {
      this.itemCount++;
      this.lessThanMinimumMsg = false;
    } else {
      this.countExceededMsg = true;
    }
    this.inputAnimate = 'green';
    setTimeout(() => {
      this.inputAnimate = 'white';
    }, 1200);
    this.timer = setTimeout(() => {
      this.countExceededMsg = false;
    }, 10000);
  }

  addToCart() {
    if(environment.underMaintenance) {
      let supportPhone = environment.supportPhone;
      this.addingMsg(`Our online order system is currently under construction. For reservations, please contact WrapMe support at this number: ${supportPhone}`, undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-error-snackbar',
      }, 10000);
      return;
    }

    this.isMessageClosed = !this.isMessageClosed;
    this.addingCartSuccess = false;
    const inventoryId = this.isItem
      ? `GiftItem-${this.productItem.itemId}`
      : `GiftPackage-${this.productPackage.packageId}`;

    if (this.isOutStock) {
      this.addingMsg(`${ this.isItem ? this.productItem.itemName : this.productPackage.packageName } is out of stock!`, undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-error-snackbar',
      }, 5000);
      return;
    }

    this.shoppingCartService
      .loadShoppingCart()
      .pipe(
        mergeMap((cart) => {
          this.currentCart = cart;
          this.cartId = cart.id;
          return this.shoppingCartService.noExistingGiftItems(
            this.cartId,
            inventoryId
          ).pipe(
            map(value => {
              return {cart : cart, success : value};
            })
          );
        }),
        mergeMap((response) => {
          if (response.success) {
            return this.shoppingCartService.addCartItemByInventoryId(
              this.cartId,
              inventoryId,
              {
                units: this.itemCount ? this.itemCount : 1,
                unitsOfMeasure: UnitsOfMeasure.qty,
              }
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: (res) => {
          if(res) {
            this.addingCartSuccess = true;
          } else {
            this.cartItemExistsMsg();
          }
        },
        error : (error : HttpErrorResponse) => {
          this.addingMsg(`Cannot add to cart, ${this.shoppingCartService.mapAddToCartError(error)}`, undefined, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: 'light-error-snackbar',
          }, 5000);
        }
      });
  }

  cartItemExistsMsg() {
    const modalRef = this.modalService.open(AddToCartAmountUpdateMessageComponent);
    modalRef.componentInstance.itemCountFromQuickDetailWindow = this.itemCount;
    modalRef.componentInstance.itemId = this.isItem ? this.productItem.itemId : this.productPackage.packageId;
  }

  getCartItem(invId: string): CartItem {
    return this.currentCart.cartItems.filter(
      (item) => item.product.inventoryId === invId
    )[0];
  }

  closeMessage() {
    this.isMessageClosed = false;
    this.countExceededMsg = false;
    this.lessThanMinimumMsg = false;
  }

  addingMsg(message: string, action = '', config?: MatSnackBarConfig, timeout? : number) {
    this._snackBar.open(message, action, config);
    setTimeout(() => {
      this._snackBar.dismiss();
    }, timeout ? timeout : 3500);
  }

  slideChanged(data: SlidesOutputData) {
    this.activeSlides = data;
    this.selectedImage = this.activeSlides.startPosition ?? 0;
    const element = document.getElementsByClassName('product-gallery__carousel-item--active')[0];
    const parent = element ? element.parentElement?.parentElement?.nextElementSibling : null;

    if(!parent?.classList.contains('active')){
      this.thumbSlider?.to(this.selectedImage.toString())
    }
  }

  changeImage(index: number){
    this.selectedImage = index;
  }
}
