import { CloudFile } from "./cloud-file";
import {ShoppingCartPricing} from "./shopping-cart-pricing";

// Generated by https://quicktype.io

export interface ShoppingCart {
    id:        number;
    currency:  string;
    cartItems: CartItem[];
    pricing : ShoppingCartPricing;
}


export interface CartItem {
    id:              number;
    quantity:        number;
    discountedPrice: number;
    serviceFee:      number;
    product:         Product;
}

export interface Product {
    id:                number;
    inventoryProduct:  InventoryProduct;
    inventoryProvider: string;
    inventoryId:       string;
    name:              string;
    price:             number;
    discount:          number;
}

export interface InventoryProduct {
    itemId:        number;
    sku:           string;
    itemName:      string;
    price:         Price;
    itemShortDesc: string;
    itemStock:     ItemStock;
    itemLongDesc:  string;
    cloudFiles:    CloudFileElement[];
    description: string; // for pure packing boxes
    id: number; // for pure packing boxes
    itemType:      string;
}

export interface ItemStock {
  availabilityEndDate: Date;
  currentStockSize: number;
  discountCode: string;
  status: string;
}

export interface Price {
    amount:   number;
    currency: string;
}

export interface CloudFileElement {
    cloudFile: CloudFile;
}

export interface InventoryEntry {
    units : number;
    unitsOfMeasure: UnitsOfMeasure;
}

export enum UnitsOfMeasure {
    qty = 'qty'
}
