import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouterEventService } from '../RouterEvent.service';

@Component({
  selector: 'app-custom-redirect',
  standalone: true,
  imports: [
    CommonModule,
  ],
  template: '',
  styleUrls: ['./custom-redirect.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomRedirectComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private rEventService: RouterEventService) { }

  ngOnInit(): void {
    const product = this.route.snapshot.paramMap.get("product")! == 'package' ? 'buy-from-store&product' : 'customized-gifts&product';
    const id = this.route.snapshot.paramMap.get("id")!;
    this.router.navigateByUrl(`/store?page=${product}=${id}`);
  }
}
