<div class="modal-header border-0">
    <h5 class="modal-title">
      Same item found in your cart!
    </h5>
</div>
<div class="modal-body justify-content-center align-items-center">
  <p class="text-center mb-0">The Item you tried to add is already in the cart.</p>
  <p class="text-center mb-5">Do you want to increase the amount?</p>
    <div class="add-to-box-wrapper d-flex justify-content-center align-items-center mb-4">
        <div class="counder-wrapper me-3">
            <div class="input-group">
                <button id="counter-decrement-btn" (click)="decrementCount(-1)"
                    class="btn btn-light border border-1 border-dark rounded-circle px-3 me-2 counter-decrement-btn">
                    <!-- <i-bs name="dash-lg"></i-bs> -->
                    <span class="fs-5">-</span>
                </button>
                <input id="counter-data-field" type="number" [@animate]='inputAnimate'
                    class="form-control rounded-pill border-1 border-dark mx-1 text-center"
                    aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" min="1"
                    readonly value="{{itemCount}}">
                <button id="counter-increment-btn" (click)="incrementCount(1)"
                    class="btn btn-light border border-1 border-dark rounded-circle px-3 ms-2 counter-increment-btn">
                    <!-- <i-bs name="plus-lg"></i-bs> -->
                    <span class="fs-5">+</span>
                </button>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer border-0 justify-content-center">
    <button type="button" class="btn btn-outline-secondary rounded-pill" data-bs-dismiss="modal" (click)="activeModal.dismiss('Close click')">Close</button>
    <button class="btn btn-primary rounded-pill" (click)="updateUnitCount()">Update Cart</button>
    <div *ngIf="countExceededMsg || lessThanMinimumMsg" id="quick-detail-message-wrapper" class="quick-detail-message-wrapper m-0 position-absolute bg-success bottom-0 start-50 translate-middle-x w-100 p-2">
      <div class="d-flex justify-content-between align-items-center">
          <div class="message-text">
              <a (click)="closeMessage()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg me-2 text-light" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
              </a>
              <span *ngIf="countExceededMsg"> Maximum Quantity Exceeded: You can purchase a maximum of {{ maxItemCount }} units at a time. For bulk order inquiries and special discounts, please contact our sales team.! </span>
              <span *ngIf="lessThanMinimumMsg"> Item count can't be less than one! </span>
          </div>
      </div>
    </div>
    <div *ngIf="isMessageClosed" id="quick-detail-message-wrapper" class="quick-detail-message-wrapper m-0 position-absolute bg-success bottom-0 start-50 translate-middle-x w-100 p-2">
      <div class="d-flex justify-content-between align-items-center">
          <div class="message-text">
              <a (click)="closeMessage()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg me-2 text-light" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
              </a>
              <span> Item is added to the cart! </span>
          </div>
          <div class="view-cart-button">
              <div id="message-wrapper-view-cart-btn" class="btn btn-outline-primary btn-light rounded-pill"
              [routerLink]="'/cart'">View cart</div>
          </div>
      </div>
    </div>
</div>
