<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- main content container -->

<div class="container position-relative z-index-2 top-0" *ngIf="!isAdmin && !isLogIn && !loading">
    <div class="row">
        <div class="col-12">
            <xeyn-header></xeyn-header>
        </div>
    </div>
</div>
<div class="container-fluid z-index-n1 ps-0">
    <div class="row">
        <div class="col-12 pe-0">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<div class="container-fluid z-index-n1 ps-0" *ngIf="!loading && !isAdmin">
  <!-- TODO <xgy-customizable-cart-popup></xgy-customizable-cart-popup>-->
    <div class="row">
        <div class="col-12">
            <xeyn-footer></xeyn-footer>
        </div>
    </div>
</div>
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content above * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * End of Placeholder * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
