import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { register as registerSwiperElements } from 'swiper/element/bundle'
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

registerSwiperElements();

if (environment.production) {
  enableProdMode();
}

if (!environment.enableLogs) {
  window.console.log = () => {}
}

setTimeout(() => {
  console.log(environment);
  if (extractEnvironment(environment.apiBaseUrl) === 'prod01') {
    initClarity();
    initFacebookPixel();
    addNoScriptPixel();
    initTagManager();
    addGoogleTagManagerNoScript();
    addGoogleSiteVerification();
    initGoogleAnalytics();
  }
}, 3000);

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

function addScript(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.src = src;
    script.type = 'text/javascript';
    head.appendChild(script);
  });
}

function extractEnvironment(url: string): string | null {
  const match = url.match(/-(.*?)-/);
  return match ? match[1] : 'dev';
}

async function initTagManager() {
  try {
    await addScript('assets/scripts/google.tag-manager.js');
  } catch (error) {
    console.error('Failed to load Tag Manager script:', error);
  }
}

async function initGoogleAnalytics() {
  try {
    await addScript('https://www.googletagmanager.com/gtag/js?id=G-P66YJ3GW84');
    await addScript('assets/scripts/google.analytics.js');
  } catch (error) {
    console.error('Failed to load Google Analytics script:', error);
  }
}

async function initClarity() {
  try {
    await addScript('assets/scripts/clarity.prod.js');
  } catch (error) {
    console.error('Failed to load Clarity script:', error);
  }
}

async function initFacebookPixel() {
  try {
    await addScript('assets/scripts/facebook.pixel.js');
  } catch (error) {
    console.error('Failed to load Facebook Pixel script:', error);
  }
}

function addNoScriptPixel() {
  const noScript = document.createElement('noscript');
  const img = document.createElement('img');
  img.height = 1;
  img.width = 1;
  img.style.display = 'none';
  img.src = 'https://www.facebook.com/tr?id=925328395885385&ev=PageView&noscript=1';
  noScript.appendChild(img);
  document.head.appendChild(noScript);
}

function addGoogleSiteVerification() {
  const meta = document.createElement('meta');
  meta.name = 'google-site-verification';
  meta.content = 'WeXlbbCNHTgvQnr8lM8RbNpNqv8npLNNv5KdUQPTS_E';
  document.head.appendChild(meta);
}

function addGoogleTagManagerNoScript() {
  const noScript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-WNNGMC4T';
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noScript.appendChild(iframe);
  document.body.appendChild(noScript);
}
