// An internal module named AppConfigLoader with an exported function 'configLoader'
import {ajax} from "rxjs/ajax";
import {AppConfig} from "./Models/app-config";
import {catchError, of, take, tap} from "rxjs";
import {environment} from "../environments/environment";

export module AppConfigLoader {
  export function configLoader() {
    return ajax.getJSON<AppConfig>('.well-known/app-config')
      .pipe(
        take(1),
        catchError((error) => {
          return of({
            apiBaseUrl: environment.apiBaseUrl,
            cdnUrl: environment.cdnUrl,
            storageFolder: environment.storageFolder,
            imageUploadAdminApiBaseUrl: environment.imageUploadAdminApiBaseUrl,
            imageTransformations: environment.imageTransformations,
            requestFormat: environment.requestFormat,
            deliveryDateBuffer: environment.deliveryDateBuffer,
            supportPhone: environment.supportPhone,
            supportEmail: environment.supportEmail,
            enableLogs: environment.enableLogs,
            underMaintenance: environment.underMaintenance,
            wrapMeAddress: environment.wrapMeAddress,
            xeynergyAddress: environment.xeynergyAddress,
            wrapMeBankAccName: environment.wrapMeBankAccName,
            accBranch: environment.accBranch,
            accNumber: environment.accNumber,
            bankName: environment.bankName,
            useWrapMeAddress: environment.useWrapMeAddress,
            maximumHeroSliderImages: environment.maximumHeroSliderImages,
            defaultCustomItemMaxCount: environment.defaultCustomItemMaxCount,
            captchaKey: environment.captchaKey,
            inventoryProvider: environment.inventoryProvider
          })
        }),
        tap((appConfig) => {
          environment.apiBaseUrl = appConfig?.apiBaseUrl;
          environment.cdnUrl = appConfig?.cdnUrl;
          environment.storageFolder = appConfig?.storageFolder ?? environment.storageFolder;
          environment.imageUploadAdminApiBaseUrl = appConfig?.imageUploadAdminApiBaseUrl ?? environment.imageUploadAdminApiBaseUrl;
          environment.imageTransformations = appConfig?.imageTransformations ?? environment.imageTransformations;
          environment.requestFormat = appConfig?.requestFormat ?? environment.requestFormat;
          environment.deliveryDateBuffer = appConfig?.deliveryDateBuffer ?? environment.deliveryDateBuffer;
          environment.supportPhone = appConfig?.supportPhone ?? environment.supportPhone;
          environment.supportEmail = appConfig?.supportEmail ?? environment.supportEmail;
          environment.enableLogs = appConfig?.enableLogs ?? environment.enableLogs;
          environment.underMaintenance = appConfig?.underMaintenance ?? environment.underMaintenance;
          environment.wrapMeAddress = appConfig?.wrapMeAddress ?? environment.wrapMeAddress;
          environment.xeynergyAddress = appConfig?.xeynergyAddress ?? environment.xeynergyAddress;
          environment.wrapMeBankAccName = appConfig.wrapMeBankAccName ?? environment.wrapMeBankAccName;
          environment.accBranch = appConfig.accBranch ?? environment.accBranch;
          environment.accNumber = appConfig.accNumber ?? environment.accNumber;
          environment.bankName = appConfig.bankName ?? environment.bankName;
          environment.useWrapMeAddress = appConfig?.useWrapMeAddress ?? environment.useWrapMeAddress;
          environment.maximumHeroSliderImages = appConfig?.maximumHeroSliderImages ?? environment.maximumHeroSliderImages;
          environment.defaultCustomItemMaxCount = appConfig?.defaultCustomItemMaxCount ?? environment.defaultCustomItemMaxCount;
          environment.captchaKey = appConfig?.captchaKey ?? environment.captchaKey;
          environment.inventoryProvider = appConfig?.inventoryProvider ?? environment.inventoryProvider;
        })
      );
  }
}
